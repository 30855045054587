import React from 'react';
import AppRouter from './Router';
import './App.css';

function App() {
  return (
      <AppRouter />
  );
}

export default App;
