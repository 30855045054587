import courierApiV1 from '../SwaggerFiles/courier-api.json';
import courierApiV3 from '../SwaggerFiles/courier-api-v3.json';

export const routes = [
    {
        path: '/courier-api/v3',
        name:'API Documentation - v3 (latest)',
        swaggerFile: courierApiV3,
        mainColor: '#f52f41',
    },
    {
        path: '/courier-api/v1',
        name:'Previous API version - v1',
        swaggerFile: courierApiV1,
        mainColor: '#f52f41',
    }
]

export const homePagePath = routes[0].path;
