import React, {useState, useEffect} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
    withRouter
} from 'react-router-dom';
import PublicApiSwagger from '../Components/PublicApiSwagger';
import "./select.css"
import {routes, homePagePath} from './routes';

function AppRouter() {
    return (
        <Router>
            <SelectVersion/>
            <Switch>
                {routes.map(route => (
                    <Route key={route.path} path={route.path}>
                        <PublicApiSwagger
                            file={route.swaggerFile}
                            mainColor={route.mainColor}
                        />
                    </Route>
                ))}
                <Redirect to={homePagePath}/>
            </Switch>
        </Router>

    );
}

function Select({history, location}) {
    const validRoutes = routes.map((route) => route.path);
    const [selectedRoute, setSelectedRoute] = useState(()=>(validRoutes.includes(location.pathname) ? location.pathname : homePagePath));

    useEffect(() => {
        if (!validRoutes.includes(selectedRoute)) {
            return;
        }
        if (selectedRoute !== location.pathname) {
            history.push(selectedRoute)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRoute, history, location.pathname])

    return (
        <div className="container-select">
            <select value={selectedRoute} onChange={({target}) => {
                setSelectedRoute(target.value)
            }}>
                {routes.map(x => (
                    <option key={x.path} value={x.path}>
                        {x.name}
                    </option>
                ))}
            </select>
        </div>
    );
}

const SelectVersion = withRouter(Select)

export default AppRouter;
