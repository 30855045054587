import React from 'react';
import PropTypes from 'prop-types';
import { RedocStandalone } from 'redoc';

function PublicApiSwagger({file, mainColor}) {
    return (
        <RedocStandalone
            spec={file}
            options={{
                nativeScrollbars: true,
                theme: { colors: { primary: { main: mainColor } } },
            }}
        />
    );
}

PublicApiSwagger.defaultProps = {
    // Peya Red Color
    mainColor: '#f52f41',
}

PublicApiSwagger.propTypes = {
    file: PropTypes.object.isRequired,
    mainColor: PropTypes.string,
}

export default PublicApiSwagger;